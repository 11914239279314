import { Injectable } from '@angular/core';
import {
  AngiolivePayQuery,
  CartChangeItemQuery,
  CartDeleteItem, CartDelivery,
  CartItem, DeliveryTypeIds,
  OrderQuery,
  UserAddressModel,
  CartUpdateCityQuery,
  ProductStatuses,
  CartAddItem
} from '@core/models';
import { CartService } from '@core/services/cart/cart.service';
import { combineLatest, Subject, Subscription, of, Observable } from 'rxjs';
import { VRStorage } from '@core/models/storage/storage.model';
import { PayService } from '@core/services/order/pay.service';
import { PayUrl, MiraclePayQuery, SensationPayQuery, ThalassolabPayQuery, HcPayQuery } from '@core/models/order/pay.model';
import { LanguageService } from '@core/services/language.service';
import { getLandingName } from '@core/lib/functions/getLandingName';
import { take, catchError, mergeMap, tap } from 'rxjs/operators';
import { VerteraAnalyticsService } from '@vertera-common/services';
import { AuthTokenService } from '@core/services/auth/auth-token.service';
import { AuthSessionService } from '@core/services/auth/auth-session.service';
import { StorageService } from '@core/services/storage/storage.service';
import { CurrencyService } from '@core/services/currency.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CityService } from '@core/services/hdbk/city.service';


@Injectable({
  providedIn: 'root'
})
export class CartViewService {

  private readonly sub$: Subscription = new Subscription();
  public items: CartItem[] = [];
  public storage: VRStorage;
  public totalPrice = 0;
  public grandTotal = 0;
  public deliveryPrice = 0;
  public totalCustomerPrice = 0;
  public totalAmount = 0;

  public change$ = new Subject<void>();
  public confirm$ = new Subject<void>();
  public paymentError$ = new Subject<void>();
  public changeStorage$: Subject<void> = new Subject();
  public orderModel: OrderQuery;
  public address: UserAddressModel;
  private _delivery: CartDelivery;

  public get availableItems() {
    return this.items.filter( (item: CartItem) => {
      return item.is_delivery_available || item.product.status.id === ProductStatuses.published;
    });
  }
  public get unavailableItems() {
    return this.items.filter( (item: CartItem) => {
      return !item.is_delivery_available || item.product.status.id === ProductStatuses.deactivated ||
      item.product.status.id === ProductStatuses.hidden;
    });
  }

  set delivery(delivery: CartDelivery) {
    this._delivery = delivery;
    if (delivery.type.id === DeliveryTypeIds.courier || delivery.type.id === DeliveryTypeIds.dpd_courier ) {
      this.storageService.setStorage(delivery.storage);
      this.storage = delivery.storage;
    }
  }

  get delivery(): CartDelivery {
    return this._delivery;
  }

  isLoading: boolean;
  isCityChosen: boolean;

  constructor(
    private cartService: CartService,
    private storageService: StorageService,
    private languageService: LanguageService,
    private payService: PayService,
    private analyticsService: VerteraAnalyticsService,
    private authService: AuthTokenService,
    private authSessionService: AuthSessionService,
    private currencyService: CurrencyService,
    private router: Router,
    private cityService: CityService
  ) {
    this.getStorage();
    this.authService.clearAuthHeaderValue();
  }

  calculateTotal() {
    this.totalPrice = 0;
    this.totalAmount = 0;
    this.totalCustomerPrice = 0;
    this.deliveryPrice = this.delivery ? (parseFloat(this.delivery.price) || parseFloat(this.delivery.min_price)) : 0;
    this.availableItems.forEach( item => {
      this.totalPrice += item.amount * item.product.prices[0].price_partner;
      this.totalCustomerPrice += item.amount * item.product.prices[0].price_customer;
      this.totalAmount += item.amount;
    });
    if (this.delivery) {
      this.grandTotal = this.totalPrice
        + ((this.delivery.type.id === DeliveryTypeIds.dpd_courier || this.delivery.type.id === DeliveryTypeIds.courier)
        ? this.deliveryPrice : 0);
    }
    this.change$.next();
  }

  subscribeToCityChange() {
    this.cityService.cityChange$.subscribe((city) => {
      if (city) {
        if (this.items.length > 0) {
          const query = new CartUpdateCityQuery();
          query.new_city_id = city.id;
          if (this.items.length) {
            query.slugs = this.itemSlugs.join(',');
           }
          const sub = this.cartService.updateCartCity(query).subscribe(() => sub.unsubscribe());
        }
      }
    });
  }

  getItemBySlug(slug: string) {
    return this.items.find( item => item.product.slug === slug);
  }

  removeItemBySlug(slug: string) {
    this.items = this.items.filter( item => item.product.slug !== slug);
  }

  getCart() {
    this.isLoading = true;
    const cityId = this.cityService.getCityId();
    if (cityId) {
     const query = new CartUpdateCityQuery();
     query.new_city_id = cityId;
     if (this.items.length) {
      query.slugs = this.itemSlugs.join(',');
     }
     return this.cartService.updateCartCity(query).pipe(
            catchError((error) =>  of(error)),
            mergeMap(() => this.cartService.getCart()),
            tap((data: CartItem[] ) => this.handleRespone(data)));
    } else {
      return this.getCartItems();
    }
  }

  getCartItems(): Observable<CartItem[]> {
    return this.cartService.getCart().pipe(tap((data: CartItem[]) => this.handleRespone(data)));
  }

  handleRespone(data: CartItem[]) {
    this.isLoading = false;
    this.items = data.sort((a, b) => a.is_delivery_available ? 1 : -1);
    this.calculateTotal();
  }

  replaceCartItems(slugs: string, previousSlug: string, amount: number): Observable<CartItem[]> {
    const city = this.cityService.getCity();
    const query: CartAddItem = {
      slugs,
      amount,
      currency_code: 'RUB',
      city_id: city.id,
      country_id: city.country.id
    };

    return this.cartService.getCart().pipe(
      mergeMap((carItems) => {
        if (carItems && carItems.length > 0) {
         const alreadyAdded = carItems.find(item => item.product.slug === slugs);
         if (alreadyAdded) {
           return of(false);
         } else {
           return of(true);
         }
        }
        return of(true);
      }),
      mergeMap((shouldAdd: boolean) => {
        if (shouldAdd) {
          return this.cartService.addToCart(query);
        } else {
          const changeQuery = new CartChangeItemQuery(slugs);
          changeQuery.amount = amount;
          return this.cartService.changeCartItem(changeQuery);
        }
      }),
      mergeMap(() => {
        const deleteQuery = new CartDeleteItem(previousSlug);
        return this.cartService.deleteCartItem(deleteQuery);
      }),
      mergeMap(() => this.getCartItems()));
  }

  getStorage() {
    this.storage = this.storageService.getStorage();
  }

  confirm() {
    this.confirm$.next();
  }

  public addToCartPostData(slugs: string): any {
    const result: any = {
      slugs, currency_code: this.currencyService.getCurrency(),
    };
    if (this.storage) {
      result.storage_id = this.storage.id;
    }
    return result;
  }

  public get itemIds() {
    if (this.items.length) {
      return this.items.map( item => item.product.id);
    } else {
      return [];
    }
  }

  public get itemSlugs() {
    if (this.items.length) {
      return this.items.map( item => item.product.slug);
    } else {
      return [];
    }
  }

  resetCart(): Observable<any> {
    return this.cartService.cleanCart().pipe(take(1), tap(() => this.reset()));
  }

  reset() {
    this.items = [];
    this.calculateTotal();
  }

  pay(query: AngiolivePayQuery | MiraclePayQuery | SensationPayQuery | ThalassolabPayQuery| HcPayQuery, route?: ActivatedRoute ) {
    this.reset();
    this.authService.clearAuthHeaderValue();
    this.authSessionService.generateNewSessionToken();
    this.analyticsService.facebookTrack('InitiateCheckout');
    this.analyticsService.googleAnalyticsTrack(`${getLandingName()}_order`);
    this.analyticsService.yandexMetrikaTrack(`${getLandingName()}_order`);
    const sub = this.payService.pay(query.query)
      .subscribe((data: PayUrl[]) => {
        if (data && data.length > 0) {
          window.location.href = data[0].url;
        } else if (route) {
          this.router.navigate(['payment'], { relativeTo: route, queryParams: { success: true }});
        }
      }, err => {
        if (route) {
          this.router.navigate(['payment'], { relativeTo: route, queryParams: { success: true }});
        }
      });
    this.sub$.add(sub);
  }

  changeStorage(newStorage: VRStorage) {
    if (this.items.length) {
      const requests = this.items.map(item => {
        const query = new CartChangeItemQuery(item.product.slug);
        query.new_storage_id = newStorage.id;
        return this.cartService.changeCartItem(query);
      });

      const sub = combineLatest(...requests).subscribe(() => {
        this.storage = newStorage;
        this.change$.next();
      }, err => {
        // delete all cart items if change storage action returns error
        this.deleteCartItems();
        this.storage = newStorage;
        this.change$.next();
      });
      this.sub$.add(sub);
    } else {
      this.storage = newStorage;
    }
  }

  deleteCartItems() {
    const itemsToDelete = this.items.map( item => {
      return this.cartService.deleteCartItem(new CartDeleteItem(item.product.slug));
    });
    if (itemsToDelete.length) {
      this.sub$.add(() => {
        combineLatest(...itemsToDelete).subscribe(() => {
          this.resetCart().subscribe();
        });
      });
    }
  }

  changeCity(route: ActivatedRoute) {
    this.getCart();
    this.router.navigate(['geo'], {relativeTo: route.parent});
  }
}
