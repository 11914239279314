import { Injectable, Inject } from '@angular/core';
import { GqlOrderQuery, Order, OrderQuery } from '@core/models/order/order.model';
import { AppStorage } from '@core/storage';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from '@core/lib/api.factory';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { VRStorage } from '@core/models/storage/storage.model';
import { getLandingName } from '@core/lib/functions/getLandingName';
import { RefService } from '@core/services/user/ref.service';
import { QueryLogService } from '../common/query-log.service';
import { StorageService } from '@core/services/storage/storage.service';
import { CurrencyService } from '../currency.service';
import { CityService } from '../hdbk/city.service';
import { DeliveryTypeIds } from '@core/models';

@Injectable()
export class OrderService {

  constructor( @Inject(AppStorage) private appStorage: Storage,
               @Inject(BASE_URL) private baseUrl: string,
               private route: ActivatedRoute,
               private http: HttpClient,
               private refService: RefService,
               private queryLogService: QueryLogService,
               public storageService: StorageService,
               private currencyService: CurrencyService,
               private cityService: CityService,
  ) { }


    getOrder(orderId: number): Observable<Order> {
      const gqlQuery = new GqlOrderQuery();
      return this.http.get<{data: Order}>(
        `${this.baseUrl}/store/order/${orderId}?${gqlQuery.toRest()}`
      ).pipe(
        map( res => res.data),
        catchError(err => throwError(err))
      );
    }

    sendOrder(query: OrderQuery): Observable<Order> {
      const gqlQuery = new GqlOrderQuery();
      query.currency_code = this.currencyService.getCurrency();
      const city = this.cityService.getCity();
      query.delivery_city_id = city.id;
      query.delivery_country_id = city.country.id;
      if (query.delivery_type_const === DeliveryTypeIds.self_pickup) {
        query.delivery_point_id = query.storage_id.toString();
      }
      const ref_code = this.route.snapshot.queryParamMap.get('ref') || this.refService.getRef();
      if (ref_code) {
        query.ref_code = ref_code;
      }
      const storage: VRStorage = this.storageService.getStorage();
      if (storage) {
        query.storage_id = storage.id;
      }
      query.stat_uuid = this.queryLogService.getUUID();
      return this.http.post<{data: Order}>(`${this.baseUrl}/store/order?${gqlQuery.toRest()}`, query).pipe(
        map( res => {
          if ( res.data) {
            this.setOrderId(res.data.id);
          }
          return res.data;
        } )
      );
  }

  setOrderId(orderId: number) {
    this.appStorage.setItem(`${environment.appName}${getLandingName()}_orderId`, orderId.toString());
  }

  getOrderId(): number {
   return +this.appStorage.getItem(`${environment.appName}${getLandingName()}_orderId`);
  }
}
