import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductService } from './services/product/product.service';
import { UserService } from './services/user/user.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppTokenInterceptorService } from './interceptors/app-token-interceptor.service';
import { AuthSessionService } from './services/auth/auth-session.service';
import { SessionInterceptor } from './interceptors/session.interceptor';
import { StorageService } from './services/storage/storage.service';
import { LiteralService } from './services/hdbk/literal.service';
import { CityService } from './services/hdbk/city.service';
import { OrderService } from './services/order/order.service';
import { MetaModule } from '@ngx-meta/core';
import { PayService } from '@core/services/order/pay.service';
import { LanguageInterceptor } from './interceptors/language.interceptor';
import { AuthTokenService } from './services/auth/auth-token.service';
import { UserConfirmService } from './services/user/confirm/user-confirm.service';
import { RefService } from '@core/services/user/ref.service';
import { TextDialogModule } from './dialogs/text-dialog/text-dialog.module';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { FavoritesService } from './services/user/favorites.service';
import { ConfirmDialogModule } from './dialogs/confirm-dialog/confirm-dialog.module';



@NgModule({
  declarations: [NotFoundComponent],
  imports: [
    CommonModule,
    TextDialogModule,
    ConfirmDialogModule,
    MetaModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppTokenInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    AuthSessionService,
    ProductService,
    UserService,
    UserConfirmService,
    StorageService,
    LiteralService,
    CityService,
    OrderService,
    PayService,
    AuthTokenService,
    RefService,
    FavoritesService
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule already loaded; import in root module only.');
    }
  }
  static forRoot(): ModuleWithProviders {
    return { ngModule: CoreModule };
  }
}

