import { Component, OnDestroy, AfterViewInit } from '@angular/core';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { QueryLogService } from '@core/services/common/query-log.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {
  sub$: Subscription = new Subscription();
  constructor(
    public angulartics: Angulartics2GoogleGlobalSiteTag,
    public route: ActivatedRoute,
    public queryLogService: QueryLogService
  ) {
    angulartics.startTracking();
  }
  ngAfterViewInit() {
    setTimeout(() => {
      const sub = this.queryLogService.sendQueryLog(this.route.snapshot.queryParams).subscribe();
      this.sub$.add(sub);
    }, 1000);
  }
  ngOnDestroy() {
    this.sub$.unsubscribe();
  }
}
