<div class="dialog__content">
  <div [innerHTML]="text"></div>
  <div class="dialog__buttons">
    <button class="dialog__btn"
                (click)="close(true)">
                <span *ngIf='data.applyButtonText'>{{data.applyButtonText}}</span>
                <span *ngIf='!data.applyButtonText' i18n='@@common.yes'>Yes</span>
              </button>
                <button class="dialog__btn"
                (click)="close()">
                <span *ngIf='data.cancelButtonText'>{{data.cancelButtonText}}</span>
                <span *ngIf='!data.cancelButtonText' i18n='@@common.cancel'>Cancel</span>
                </button>
  </div>
</div>
