import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthTokenService } from '@core/services/auth/auth-token.service';
import { tap, flatMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    isAuth$ = new BehaviorSubject<boolean>(false);
    constructor(private injector: Injector) {
        // this.subscribeAuth();
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.injector.get(AuthTokenService);
        // const auth = this.injector.get(AuthService);
        return token.getAuthorizationHeaderValue().pipe(
            flatMap(authHeader => {
                if (authHeader) {
                    this.isAuth$.next(true);
                    const multiplyHeaders = req.headers.set('Authorization', authHeader);
                    const authReq = req.clone({ headers: multiplyHeaders });
                    return next.handle(authReq);
                } else {
                  return next.handle(req);
                }
            }));
    }
    // IF Auth in another browser tabs
    /*
    private subscribeAuth() {
        this.isAuth$.pipe(distinctUntilChanged()).subscribe(isAuth => {
            if (isAuth) {
                this.store.dispatch(getUserProfile());
            }
        });
    }*/
}
