import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '@core/lib/api.factory';
import { Observable, throwError } from 'rxjs';
import {
  CartAddItem,
  CartChangeItemQuery,
  CartDeleteItem,
  CartDelivery, CartDeliveryQuery,
  CartIncrementQuery,
  CartItem, GqlCartDeliveryQuery,
  GqlCartQuery,
  CartUpdateCityQuery
} from '@core/models';
import { catchError, map } from 'rxjs/operators';
import { ProductService } from '../product/product.service';
import { CityService } from '../hdbk/city.service';


@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(
    private http: HttpClient,
    private productService: ProductService,
    private cityService: CityService,
    @Inject(BASE_URL) private baseUrl: string
  ) { }

  getCart(): Observable<CartItem[]> {
    const country_id = this.cityService.getCountryId();
    const query = {country_id};
    const gqlQuery = new GqlCartQuery(query);
    return this.http.get<{data: CartItem[]}>(
      `${this.baseUrl}/store/cart?${gqlQuery.toRest()}`
    ).pipe(
      map( res => {
        res.data.forEach((cartItem: CartItem) => {
          this.productService.parseProductPrice(cartItem.product);
        });
        return res.data;
      } ),
      catchError(err => throwError(err))
    );
  }

  getCartDelivery(query?: CartDeliveryQuery): Observable<CartDelivery[]> {
    const gqlQuery = query ? new GqlCartDeliveryQuery(query) : new GqlCartDeliveryQuery();
    return this.http.get<{data: CartDelivery[]}>(`${this.baseUrl}/store/cart/delivery?${gqlQuery.toRest()}`)
      .pipe(
        map(res => res.data)
      );
  }

  addToCart(item: CartAddItem): Observable<any> {
    item.amount = item.amount ? item.amount : 1;
    const city = this.cityService.getCity();
    if (city.id) {
      item.city_id = city.id;
    }
    if (city.country) {
      item.country_id = city.country.id;
    }
    return this.http.post(`${this.baseUrl}/store/cart`, item);
  }

  changeCartItem(query: CartChangeItemQuery): Observable<any> {
    const putQuery = Object.assign({}, query);
    if (putQuery.new_storage_id) {
      putQuery.storage_id = putQuery.new_storage_id;
      delete putQuery.new_storage_id;
    }
    const city = this.cityService.getCity();
    if (city.id) {
      putQuery.city_id = city.id;
    }
    if (city.country) {
      putQuery.country_id = city.country.id;
    }
    return this.http.put(`${this.baseUrl}/store/cart/update`, putQuery);
  }

  deleteCartItem(item: CartDeleteItem): Observable<any> {
    const options = {
      headers: new HttpHeaders(),
      body: item.query
    };
    return this.http.delete(`${this.baseUrl}/store/cart`, options);
  }

  // deletes all items from a cart
  cleanCart(): Observable<any> {
    return this.http.delete(`${this.baseUrl}/store/cart`);
  }

  incrementCartItem(query: CartIncrementQuery): Observable<any> {
    return this.http.put(`${this.baseUrl}/store/cart/inc`, query);
  }

  decrementCartItem(query: CartIncrementQuery): Observable<any> {
    return this.http.put(`${this.baseUrl}/store/cart/dec`, query);
  }

  updateCartCity(query: CartUpdateCityQuery) {
    return this.http.put(`${this.baseUrl}/store/cart/update-multiple?fields=slug,is_delivery_available`, query);
  }
}

