import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppTokenService } from '@core/services/app-token.service';

@Injectable({
  providedIn: 'root'
})
export class ThalassolabAppTokenGuard implements CanActivate {
  constructor(private apptokenService: AppTokenService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.apptokenService.setToken(environment.thalassolab.token);
      return true;
  }
}
