import { Injectable, Inject } from '@angular/core';
import { BASE_URL } from 'src/app/core/lib/api.factory';
import {
  Product, GqlProductQuery, ProductQuery, AngioliveProductsOsIds, Price,
} from '../../models/product/product.model';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { PageResult } from '@core/models/page/page.model';
import { CurrencyService } from '../currency.service';

@Injectable()
export class ProductService {
  thalassolabSlugs = ['thalasso-lab-full', 'thalasso-lab-start'];
  angioliveSlugs = [
    'angiolive-gel-cherry', 'angiolive-mask-250', 'angiolive-gel-apple', 'angiolive-gel-blackcurr', 'angiolive-gel', 'angiolive-mask-500'
  ];
  angioliveVcSlugs = [
    'angiolive-mask-250', 'angiolive-mask-500', 'seaweed-gel', 'seaweed-gelcold', 'face-mask', 'seaweed-gelhot'
  ];

  constructor(
    private http: HttpClient,
    private currencyService: CurrencyService,
    @Inject(BASE_URL) private baseUrl: string,
  ) {
  }

  getProducts(query: ProductQuery): Observable<Product[]> {
    const _query = Object.assign({}, query);
    if (_query.city_ids) {
      delete _query.city_ids;
    }
    const gqlQuery = new GqlProductQuery(_query);
    return this.http
      .get<{ data: Product[] }>(
        `${this.baseUrl}/store/product?${gqlQuery.toRest()}`
      )
      .pipe(
        map(res => {
          this.parseProductsPrices(res.data);
          this.setCurrency(res.data);
          return res.data;
        }),
        catchError(err => throwError(err))
      );
  }

  getInstancesWithPagination(query: ProductQuery): Observable<PageResult<Product[]>> {
    const gqlQuery = new GqlProductQuery(query);
    return this.http
      .get<any>(`${this.baseUrl}/store/product?${gqlQuery.toRest()}`, { observe: 'response' })
      .pipe(
        map(res => {
          return new PageResult<Product[]>(res);
        }),
        catchError(err => throwError(err))
      );
  }

  getAngioliveSlugs(): string {
    return this.angioliveSlugs.join(',');
  }

  getAngioliveVcSlugs(): string {
    return this.angioliveVcSlugs.join(',');
  }

  getThalassolabSlugs(): string {
    return this.thalassolabSlugs.join(',');
  }

  public parseProductsPrices(products: Product[]) {
    products.forEach((product: Product) => {
      this.parseProductPrice(product);
    });
  }

  public parseProductPrice(product: Product) {
    product.price = {};
    if (product.prices) {
      product.prices.forEach((price: Price) => {
        product.price[price.currency_code] = price;
      });
    }
    this.setCurrency([product]);
  }

  setCurrency(products: Product[]) {
    if (products && products.length > 0) {
      this.currencyService.setCurrency(products[0].currency);
    }
  }
}
