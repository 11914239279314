import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from '@core/lib/api.factory';
import { CityQuery, GqlCityQuery, City } from '@core/models/hdbk/city.model';
import { Observable, Subject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CityQueryModel, CityModel } from '@core/models/geo/city.model';
import { PageResult } from '@core/models/page/page.model';
import { AppStorage } from '@core/storage';
import { environment } from 'src/environments/environment';
import { getLandingName } from '@core/lib/functions/getLandingName';

@Injectable()
export class CityService {

  readonly cityKey: string = `${environment.appName}_${getLandingName()}_city`;
  readonly cityChange$ = new Subject<CityModel | void>();

  readonly CITY = {
    moscow: {
      id: 5469360,
      name: 'Москва',
      country: {
        id: 113,
        alpha2: 'RU',
        name: 'Россия',
      }
    },
    istanbul: {
      id: 5478955,
      name: 'İstanbul',
      country: {
        id: 314,
        name: 'Türkiye',
        cities: [],
        alpha2: 'TR'
      },
    },
    baku: {
      id: 5469592,
      name: 'Bakı',
      country: {
        id: 9,
        name: 'Azərbaycan',
        cities: [],
        alpha2: 'AZ'
      },
    },
  };

  constructor(
    private http: HttpClient,
    @Inject(AppStorage) protected appStorage: Storage,
    @Inject(BASE_URL) private baseUrl: string
  ) {
  }

  getPage(query: CityQueryModel): Observable<PageResult<CityModel[]>> {
    const gqlQuery = new GqlCityQuery();
    return this.http.get<any>(`${this.baseUrl}/hdbk/geo/city?${gqlQuery.toRest()}&${query.getQuery()}`
      , { observe: 'response' })
      .pipe(
        map(resp => new PageResult<CityModel[]>(resp))
      );
  }

  getCities(query: CityQuery): Observable<City[]> {
    const gqlQuery = new GqlCityQuery(query);
    return this.http
      .get<{ data: City[] }>(
        `${this.baseUrl}/hdbk/geo/city?${gqlQuery.toRest()}`
      )
      .pipe(
        map(res => {
          return res.data;
        }),
        catchError(err => throwError(err))
      );

  }

  setCity(city: CityModel) {
    this.appStorage.setItem(this.cityKey, JSON.stringify(city));
    this.cityChange$.next(city);
  }

  getCity(): CityModel {
    const city = this.appStorage.getItem(this.cityKey);
    return city ? JSON.parse(city) : null;
  }

  removeCity() {
    this.appStorage.removeItem(this.cityKey);
  }

  getCityId(): number {
    const city = this.getCity();
    return city ? city.id : null;
  }
  getCountryId(): number {
    const city = this.getCity();
    return city ? city.country.id : null;
  }
  getCountryCode(): string {
    const city = this.getCity();
    if (city && city.country && city.country.alpha2) {
      return city.country.alpha2;
    } else {
      return 'RU'; // Russia
    }
  }
}
