import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { BASE_URL } from './core/lib/api.factory';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { CookieService } from 'ngx-cookie-service';
import { Angulartics2Module } from 'angulartics2';
import { MetrikaModule } from 'ng-yandex-metrika';

const yaMetrikaConf = {
  id: environment.angiolive.yaMetrika,
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true,
  webvisor: true,
  trackHash: true,
  ecommerce: 'dataLayer'
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CoreModule.forRoot(),
    Angulartics2Module.forRoot(),
    MetrikaModule.forRoot(yaMetrikaConf, environment.angiolive.yaMetrika),
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: BASE_URL,
      useValue: environment.baseUri
    },
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
