<div *ngIf='!language' class="preloader" id="preloader" [@simpleFadeAnimation]="'in'">
  <div class="dws-progress-bar"></div>
</div>
<div class="container">
  <h1 i18n="@@not_found.title">
    Oops... There is no such page
    </h1>
    <h3> <span i18n="@@not_found.msg">You have accessed a page that doesn’t exist </span><br>
      <a *ngIf='link' i18n="@@not_found.return" [href]="link" >Return to the main page</a></h3>
</div>

