import { Injectable, Inject } from '@angular/core';
import { BASE_URL } from '../../lib/api.factory';
import { HttpClient } from '@angular/common/http';
import {
  UserBackCallQuery, UserRegModel, TokenModel, UserAddressModel, UserAdvertQuery, GqRegQuery
} from '../../models/user/user.model';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { RefService } from '@core/services/user/ref.service';
import { QueryLogService } from '../common/query-log.service';
import { AuthSessionService } from '../auth/auth-session.service';
import { AuthTokenService } from '../auth/auth-token.service';
import { CartViewService } from '@shared/cart/cart-view.service';
import { OrderQuery, DeliveryTypeIds } from '@core/models';
import { MatDialog } from '@angular/material';
import { TextDialogComponent } from '@core/dialogs/text-dialog/text-dialog.component';
import { validationErrorText } from '@core/lib/functions/errors';
import { REG_ERROR } from '@core/lib/translations';
import { LanguageService } from '../language.service';

@Injectable()
export class UserService {
  regModel: UserRegModel;
  registeredUserCache: UserRegModel;
  addressQuery: UserAddressModel;

  constructor(
    private http: HttpClient,
    @Inject(BASE_URL) private baseUrl: string,
    private refService: RefService,
    private queryLogService: QueryLogService,
    private sessionService: AuthSessionService,
    private authToken: AuthTokenService,
    protected cartViewService: CartViewService,
    private dialog: MatDialog,
    private languageService: LanguageService
  ) { }

  createUserCall(userBackCallQuery: UserBackCallQuery) {
    const ref = this.refService.getRef();
    if (ref) {
      userBackCallQuery.ref_code = ref;
    }
    userBackCallQuery.stat_uuid = this.queryLogService.getUUID();
    return this.http.post<any>(`${this.baseUrl}/user/back-call`, userBackCallQuery);
  }

  register(model: UserRegModel): Observable<TokenModel> {
    const ref_code = this.refService.getRef();
    if (ref_code) {
      model.ref_code = ref_code;
    }
    const gqlQuery = new GqRegQuery();
    return this.http.post<{data: TokenModel}>(`${this.baseUrl}/user/reg?${gqlQuery.toRest()}`, model).pipe(
      map( res => {
        // on successfull registraton store user data
        this.registeredUserCache = Object.assign({}, model);
        this.onRegistration(res.data);
        return res.data;
      } ),
      catchError(err => {
        this.showRegistrationError(err);
        return throwError(err);
      })
    );
  }

  setAddress(model: UserAddressModel): Observable<UserAddressModel> {
    return this.http.post<{data: UserAddressModel}>(`${this.baseUrl}/user/address?fields=id`, model).pipe(
      map( res => {
        this.onSetAddress(model, res.data);
        return res.data;
      } )
    );
  }

  setAdvertising(queryModel: UserAdvertQuery): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/user/advertising`, queryModel);
  }

  isSkipRegistration(regQuery: UserRegModel): boolean {
    if (this.authToken.getAuthHeader() && this.registeredUserCache) {
      for (const prop in this.regModel) {
        if (this.registeredUserCache[prop] !== regQuery[prop]) {
          return false;
        }
      }
      return true;
    }
  }

 private onRegistration(res: TokenModel ) {
    this.sessionService.removeSessionToken();
    this.authToken.setAuthHeader(res.token);
  }
 private onSetAddress(model, data) {
    this.cartViewService.address =  {...model, ...{delivery_user_address_id : data.id} };
    this.cartViewService.orderModel = {
      ...new OrderQuery(),
      ...this.cartViewService.address,
      ...{
        delivery_type_const: this.cartViewService.delivery.type.id,
        delivery_type_calculation_const: this.cartViewService.delivery.type_calculation.id,
        storage_id: this.cartViewService.delivery.storage.id,
      }
    };
    this.addressQuery = null;
  }

  showRegistrationError(err) {
    setTimeout(() => {
      this.dialog.open(TextDialogComponent, {
        width: '300px',
        panelClass: 'modal-form-error',
        data: validationErrorText(err.error) + '. ' + REG_ERROR[this.languageService.getLanguage()]
     });
    }, 500);
  }
}
