import { gqlProductFields, Product, ProductIds } from '@core/models/product/product.model';
import { gqlUserFields, User } from '@core/models/user/user.model';
import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';
import { VRStorage } from '@core/models/storage/storage.model';

export type DeliveryTypeId = 1 | 2 | 3 | 4;

export enum DeliveryTypeIds {
  self_pickup = 1,
  russian_post,
  courier,
  dpd_courier,
  ozon_pickpoint,
}

export interface CartItem {
  amount: number;
  created_at: string;
  currency_code: string;
  product: Product;
  user: User;
  is_delivery_available: boolean;
}

export interface CartDelivery {
  id: number;
  price: string;
  min_price: string;
  currency_code: string;
  address: string;
  type: {
    id: DeliveryTypeId,
    name: string,
  };
  selected?: boolean;
  type_calculation: {
    id: number,
    name: string
  };
  storage: VRStorage;
}

export class CartIncrementQuery {
  city_id: number;
  storage_id: number;
  constructor(
    public slug: string,
  ) {
  }
}

export class CartChangeItemQuery {
  public amount?: number;
  public new_storage_id?: number;
  public city_id: number;
  public country_id: number;
  public storage_id: number;

  constructor(
    public slug: string
  ) {
  }
}

export class CartDeleteItem {
  public storage_id: number;
  constructor(
    public slug: string
  ) {
  }

  get query() {
    return {
      slugs: this.slug,
      storage_id: this.storage_id ? this.storage_id.toString() : '',
    };
  }
}

export class CartDeliveryQuery {
  country_id: number;
  city_id: number;
}

export interface CartAddItem {
  slugs: string;
  product_id?: number;
  storage_id?: number;
  currency_code: string;
  amount?: number;
  city_id: number;
  country_id: number;
}

export const gqlCartFields = `{
  amount
  created_at
  currency_code
  product ${gqlProductFields}
  user ${gqlUserFields}
  is_delivery_available
}`;

export const gqlCartDeliveryFields = `{
  id
  storage
  price
  min_price
  currency_code
  address
  type
  type_calculation
}`;

export class GqlCartQuery extends GqlQuery {
  fields = gqlCartFields;
}

export class GqlCartDeliveryQuery extends GqlQuery {
  fields = gqlCartDeliveryFields;
}

export class CartUpdateCityQuery {
  slugs?: string;
  new_city_id: number;
}
