export const landings = [
  'angiolive', 'angiolive-b', 'vertera-gel', 'detox-juice',
  'sensation', 'miracle', 'thalassolab', 'hc', 'verterajuice'
];

export function getLandingName(): string {
  let currentLanding = '';
  for (const land of landings) {
    if (location.href.includes(land)) {
      currentLanding = land;
      break;
    }
  }
  return currentLanding;
}
