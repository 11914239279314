import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { LanguageService } from '@core/services/language.service';
import { style, state, trigger, transition, animate } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  animations: [
     trigger('simpleFadeAnimation', [
      state('in', style({opacity: 0})),
      transition(':enter', [
        style({opacity: 1}),
        animate(300 )
      ]),
      transition(':leave',
        animate(300, style({opacity: 0})))
    ])
  ]
})
export class NotFoundComponent implements OnInit {
  link: string;
  language: string;

  constructor(
    private languageService: LanguageService,
    @Inject(PLATFORM_ID) private platform: any,
    @Inject('LOCATION_HREF') private location_href: any,
  ) { }

  ngOnInit() {
    const language = this.languageService.getLanguage();
    this.language = language;
    if (isPlatformBrowser(this.platform)) {
      this.link = location.href.includes('/b') ? `/${language}/b` : `/${language}/`;
    } else {
      this.link = this.location_href.includes('/b') ? `/${language}/b` : `/${language}/`;
    }
  }
}
