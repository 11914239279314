import { AuthTokenService } from '@core/services/auth/auth-token.service';
import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';


import { environment } from 'src/environments/environment';
import { AppStorage } from '@core/storage';
import { Guid } from '@core/lib/utils/guid';

@Injectable()
export class AuthSessionService {
  private sessionKey = `${environment.appName}_session`;
  constructor(
    @Inject(AppStorage) private _appStorage: Storage,
    private authTokenService: AuthTokenService,
    ) { }

    getSessionHeaderValue(): Observable<any> {
      // X-Session-Token
      let session_token = this._appStorage.getItem(this.sessionKey);
      const authToken = this.authTokenService.getAuthHeader();
      if (authToken) {
        return of('');
      }
      if (!session_token && !/^[\w\d-]{30,40}$/.test(session_token)) {
          session_token = this.generateNewSessionToken();
      }
      return of(session_token);
    }

    generateNewSessionToken(): string {
      const session_token = Guid.newGuid();
      this._appStorage.setItem(this.sessionKey, session_token);
      return session_token;
    }

    removeSessionToken() {
      this._appStorage.removeItem(this.sessionKey);
    }
}
