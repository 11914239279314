export const environment = {
  appName: 'vertera_dev_24',
  production: false,
  baseUri: 'https://api.dev.new.vertera.org/ru/v1',
  angiolive: {
    token: '0a91d583-1ae7-495c-bfc3-e20fc055a20d',
    url: 'https://landings.dev.vertera.org/',
    paySuccessUrl: '/land/angiolive/payment?success=',
    yaMetrika: 55999003,
    b: {
      token: 'ec90ebd1-5b9c-4898-b865-5c9c8716e8cc',
    },
    c: {
      token: '8c1fae11-fd7f-4178-8548-17be17746eb7',
      url: 'https://landings.dev.vertera.org/',
    }
  },
  thalassolab: {
    token: '8b367642-fafd-42ca-8f3f-3ace1534baae',
    url: 'https://landings.dev.vertera.org/',
    paySuccessUrl: '/land/thalassolab/payment?success=',
    mapsApiKey: 'AIzaSyC9ei_wsWjYkVpAMJkiuWq3ufsnkAhPvmg',
    yaMetrika: 56761948,
  },
  hc: {
    token: 'f2111f09-45bc-4a1a-8e38-d4151f2a2a22',
    url: 'https://landings.dev.vertera.org/',
    paySuccessUrl: '/hc/payment?success=',
    mapsApiKey: 'AIzaSyC9ei_wsWjYkVpAMJkiuWq3ufsnkAhPvmg',
  },
  sensation: {
    token: 'e5c6bd03-d248-4193-8a4b-4a551fddf7dd',
    esToken: '3939abbc-022c-4c27-990c-42f1264f81fd',
    url: 'https://landings.dev.vertera.org/',
    paySuccessUrl: '/sensation/payment?success=',
    mexicoLink: 'https://dev.mx.vertera.org/es/'
  },
  miracle: {
    token: 'b967a72b-4e80-4818-930b-c54b2535dfe2',
    url: 'https://landings.dev.vertera.org/',
    paySuccessUrl: '/land/miracle?success=',
  },
  verteraGel: {
    token: '95a6012c-3b55-4b50-b90b-bdf247fbbbb7',
    yaMetrika: 56041366,
  },
  detoxJuice: {
    token: '314b15ef-ebb4-480f-a187-7ec005f0c5f7',
  },
  vertera: {
    token: '4c53b891-4230-4918-862f-7bda9abd7141',
    url: 'https://dev.new.vertera.org',
    az: {
      url: 'https://dev.azer.vertera.org'
    },
    tr: {
      url: 'https://dev.turkey.vertera.org'
    },
    eu: {
      url: 'https://dev.eu.vertera.org'
    }
  },
  staticUrl: 'https://static.vertera.org',
  refundLink: 'https://old.dev.vertera.org/en/retuns-refunds-policy',
};
