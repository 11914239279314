import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { BASE_URL } from '@core/lib/api.factory';
import { HttpClient } from '@angular/common/http';
import { QueryLog } from '@core/models/queryLog/queryLog';
import { Params } from '@angular/router';
import { AppStorage } from '@core/storage';
import { Guid } from '@core/lib/utils/guid';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class QueryLogService {

  constructor(
    @Inject(BASE_URL) private baseUrl: string,
    @Inject(AppStorage) private appStorage: Storage,
    @Inject(PLATFORM_ID) private platform: any,
    private http: HttpClient,
  ) {
  }

  sendQueryLog(qParams: Params): Observable<any> {
    if (isPlatformBrowser(this.platform)) {
      this.saveParameters(qParams);
      const query: QueryLog = this.getQueryLog(qParams);
      return this.http.post<any>(`${this.baseUrl}/common/query-log`, query);
    }
  }

  saveParameters(qParams: Params) {
    if (isPlatformBrowser(this.platform)) {
      if (qParams.utm_source) {
        this.appStorage.setItem('utm_source', qParams.utm_source);
      }
      if (qParams.utm_medium) {
        this.appStorage.setItem('utm_medium', qParams.utm_medium);
      }
      if (qParams.utm_campaign) {
        this.appStorage.setItem('utm_campaign', qParams.utm_campaign);
      }
      if (qParams.utm_term) {
        this.appStorage.setItem('utm_term', qParams.utm_term);
      }
      if (qParams.utm_content) {
        this.appStorage.setItem('utm_content', qParams.utm_content);
      }
      if (!this.appStorage.getItem('uuid')) {
        this.appStorage.setItem('uuid', Guid.newGuid());
      }
    }
  }

  getQueryLog(qParams: Params): QueryLog {
    if (isPlatformBrowser(this.platform)) {
      const queryLog: QueryLog = new QueryLog();
      queryLog.uuid = this.generateUUID();
      queryLog.google_analytics_client_id = this.appStorage.getItem('_ga');
      queryLog.yandex_metric_client_id = this.appStorage.getItem('_ym_uid');
      queryLog.utm_source = qParams.utm_source || this.appStorage.getItem('utm_source');
      queryLog.utm_medium = qParams.utm_medium || this.appStorage.getItem('utm_medium');
      queryLog.utm_campaign = qParams.utm_campaign || this.appStorage.getItem('utm_campaign');
      queryLog.utm_content = qParams.utm_content || this.appStorage.getItem('utm_content');
      queryLog.utm_term = qParams.utm_term || this.appStorage.getItem('utm_term');
      for (const prop in queryLog) {
        if (!queryLog[prop]) {
          delete queryLog[prop];
        }
      }
      return queryLog;
    }
  }

  generateUUID() {
    const uuid = Guid.newGuid();
    this.appStorage.setItem('uuid', uuid);
    return uuid;
  }

  getUUID() {
    return this.appStorage.getItem('uuid');
  }
}
