import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MetaGuard } from '@ngx-meta/core';
import { NotFoundComponent } from '@core/components/not-found/not-found.component';
import { HcAppTokenGuard } from './landing/hc/guards/hc-app-token.guard';
import { ThalassolabAppTokenGuard } from './landing/thalassolab/thalassolab-app-token.guard';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'angiolive'
  },
  {
    path: '',
    canActivateChild: [MetaGuard],
    children: [
      {
        path: 'angiolive',
        loadChildren: () => import('src/app/landing/angio-live/angio-live.module').then(m => m.AngioLiveModule),
      },
      {
        path: 'vertera-gel',
        loadChildren: () => import('src/app/landing/vertera-gel/vertera-gel.module').then(m => m.VerteraGelModule),
        pathMatch: 'full',
      },
      {
        path: 'detox-juice',
        loadChildren: () => import('src/app/landing/detox-juice/detox-juice.module').then(m => m.DetoxJuiceModule),
        pathMatch: 'full',
      },
      {
        path: 'sensation',
        loadChildren: () => import('src/app/landing/sensation/sensation.module').then(m => m.SensationModule),
      },
      {
        path: 'miracle',
        loadChildren: () => import('src/app/landing/miracle/miracle.module').then(m => m.MiracleModule),
        pathMatch: 'full',
      },
      {
        path: 'thalassolab',
        canActivate: [ThalassolabAppTokenGuard],
        loadChildren: () => import('src/app/landing/thalassolab/thalassolab.module').then(m => m.ThalassolabModule),
      },
      {
        path: 'hc',
        canActivate: [HcAppTokenGuard],
        loadChildren: () => import('src/app/landing/hc/hc.module').then(m => m.HcModule),
      },
      {
        path: 'verterajuice',
        loadChildren: () => import('src/app/landing/verterajuice/verterajuice.module').then(m => m.VerterajuiceModule),
        pathMatch: 'full',
      },
      {
        path: 'smart-kids',
        loadChildren: () => import('src/app/landing/smart-kid/smart-kid.module').then(m => m.SmartKidModule),
        pathMatch: 'full',
      },
    ]
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
