import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';

export class UserBackCallQuery {
  fname: string;
  lname: string;
  phone: string;
  ref_code?: string;
  stat_uuid: string;
  data_json: string;
}

export interface User {
  id: number;
  fname: string;
  mnamme: string;
  lname: string;
  language: string;
  is_one_plus_one: boolean;
  active_date_days: number;
}

export interface UserRegModel {
  last_name: string;
  first_name: string;
  ref_code: string;
  email?: string;
  phone: string;
  is_return_exist_user: boolean;
  country_code: string;
  language: string;
}

export interface TokenModel {
  token: string;
  expired: string;
  user: User;
}

export type UserConfirmVariantType = 1 | 2; // 1 - phone, 2 - email

export enum UserConfirmVariant {
  phone = 1,
  email
}
export class UserConfirmQuery {
  type_const: UserConfirmVariantType = UserConfirmVariant.phone;
  recipient: string;
}

export class UserValidateQuery extends UserConfirmQuery {
  code: string;
}

export interface UserAddressModel  {
  id?: number;
  country_id: number;
  region_id?: number;
  area_id?: number;
  city_id: number;
  street: string;
  zip_code: string;
  address?: string;

  build_number: string;
  build_block?: string;
  apart_number?: string;

  fname: string;
  lname: string;
  mname: string; // Required ?

  email?: string;
  phone: string;
}

export enum BrochureType {
  angiolive = 1,
  hc = 2
}

export class UserAdvertQuery {
  type_const: BrochureType.angiolive | BrochureType.hc;
  fname: string;
  lname?: string;
  email: string;
  ref_code?: string;
  data_json?: any;
}

export const gqlUserFields = `{
  id
  fname
  mnamme
  lname
  language
}`;

export const gqlRegFields = `{
  token
  expired
  user {
    id
    fname
    mnamme
    lname
    language
    active_date_days
    is_one_plus_one
  }
}`;


export class GqRegQuery extends GqlQuery {
  fields = gqlRegFields;
}
