import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';

export class FavoritesQuery {
  search: string;
}

export class FavoritesAddQuery {
  product_ids: string;
}

export class FavoritesModel {
  id: number;
  created: string;
  product: {
    id: string,
    title: string
  };
}

export const gqlFavoritesField = `{
  id
  created
  product {
    id
    title
  }
}`;

export class GqlFavoritesQuery extends GqlQuery {
  fields = gqlFavoritesField;
}
