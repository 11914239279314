import { getLandingName } from '@core/lib/functions/getLandingName';
import { environment } from 'src/environments/environment';
import { Inject, Injectable, Injector } from '@angular/core';
import { CityService } from '@core/services/hdbk/city.service';
import { HcProductSlugs, HcActiveDateUserSlugs, User, CartItem } from '@core/models';
import { of, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { CartViewService } from '@shared/cart/cart-view.service';
import { AppStorage } from '@core/storage';

@Injectable({
  providedIn: 'root'
})
export class HcProductsHelperService {
  readonly activeSlugKey = `${environment.appName}_${getLandingName()}_active_slug`;
  constructor(
    private cityService: CityService,
    @Inject(AppStorage) private appStorage: Storage,
    private injector: Injector,
  ) { }

  getProductSlug(): string {
    const city = this.cityService.getCity();
    if (city) {
      return this.getProductSlugByCountry(city.country.id);
    }
  }

  getProductSlugByCountry(countryid: number) {
    if (countryid === this.cityService.CITY.moscow.country.id) {
      return `${HcProductSlugs.H_Collagen}`;
    } else {
      return`${HcProductSlugs.Set_H_Collagen}`;
    }
  }

  getProductSlugsForActiveDatesUser() {
    const city = this.cityService.getCity();
    if (city.country.id === this.cityService.CITY.moscow.country.id) {
      return `${HcActiveDateUserSlugs.H_Collagen}`;
    } else {
      return`${HcActiveDateUserSlugs.Set_H_Collagen}`;
    }
  }

  replaceProductSlugsForActiveDatesUser(user: User): Observable<CartItem[]> {
    const cartViewService = this.injector.get(CartViewService);
    const currentAmount = cartViewService.items[0].amount;
    const slugs = this.getProductSlugsForActiveDatesUser();
    const prevSlugs = this.getProductSlug();
    if (user.is_one_plus_one || this.isActiveSlug()) {
      this.setActiveSlug();
      return cartViewService.replaceCartItems(slugs, prevSlugs, currentAmount).pipe(
      map( (cartItems: CartItem[]) => {
       if (cartItems[0].is_delivery_available && cartItems[0].product.price) {
         return cartItems;
       } else {
         return null;
       }
      }),
      mergeMap((cartItems) => {
        if (cartItems) {
          return of(cartItems);
        } else {
          return cartViewService.replaceCartItems(prevSlugs, slugs, currentAmount);
        }
      }));
    } else {
      this.removeActiveSlug();
      return of(null);
    }
  }

  setActiveSlug() {
    this.appStorage.setItem(this.activeSlugKey, 'true');
  }

  isActiveSlug() {
    return this.appStorage.getItem(this.activeSlugKey);
  }

  removeActiveSlug() {
    this.appStorage.removeItem(this.activeSlugKey);
  }
}
