import { GqlQuery } from 'src/app/core/lib/helpers/graph-ql/graph-ql';
import { environment } from 'src/environments/environment';

export enum AngioliveProductsOsIds {
  AngioLive_Food_Original = 636,
  AngioLive_Food_Black_Currant = 639,
  AngioLive_Food_Cherry = 638,
  AngioLive_Food_Apple = 637,
  AngioLive_Mask_580 = 640,
  AngioLive_Mask_250 = 641
}

export enum AngioliveVcProductSlugs {
  AngioLive_Mask_250 = 'angiolive-mask-250',
  AngioLive_Mask_580 = 'angiolive-mask-500',
  Seaweed_Gel = 'seaweed-gel',
  Seaweed_Gelcold = 'seaweed-gelcold',
  Seaweed_Gelhot = 'seaweed-gelhot',
  Face_Mask = 'face-mask',
}

export const SensationProductsSlugs = {
  Sensation_Light_60_Pills: 'binar-x-goods-sensation-light',
  Sensation_90_Pills: 'premium-sensation',
};

const hcDevSlugs = {
  H_Collagen: 'h-collagen',
  Set_H_Collagen: 'set-h-collagen'
};

const hcProdSlugs = {
  H_Collagen: 'h-collagen', // set-h-collagen-1p1
  Set_H_Collagen: 'set-h-collagen' // 'set-h-collagen-1p1es'
};


export const HcProductSlugs = environment.production ? hcProdSlugs : hcDevSlugs;

export const HcActiveDateUserSlugs = {
  H_Collagen: 'set-h-collagen-1p1',
  Set_H_Collagen: 'set-h-collagen-1p1es'
};

export enum ThalassolabProductsOsIds {
  Thalassolab_3 = 710,
  Thalassolab_10 = 709,
}

export enum ProductStatuses {
  published = 10,
  deactivated = 20,
  hidden = 30
}

interface Image {
  id: number;
  uri: string;
}

interface Status {
  id: number;
  name: string;
}

export class Product {
  id: number;
  title: string;
  images: Image;
  slug: string;
  product_os_id: number;
  points: number;
  short_description: string;
  prices: Price[];
  price?: {[code: string]: Price};
  price_customer: number;
  price_partner: number;
  main_image: Image;
  currency: string;
  status: Status;

  is_new: boolean;
  is_hit: boolean;
  // is_top: boolean;
  avg_rating: number;
  count_reviews: number;
  in_cart: boolean;
  in_favorites: boolean;

  weight_netto: number;
  weight_brutto: number;
}

export class Price {
  currency_code: string;
  price_partner: number;
  price_customer: number;
}

export class ProductQuery {
  ids?: string;
  product_os_ids?: string;
  storage_id?: number;
  slugs?: string;
  category_id?: number;
  category_ids?: number | number[];
  category_alias?: string;
  sort?: string;
  is_hidden?: number;
  country_id?: number;
  city_ids?: number | string;
}

export class ProductIds {
  constructor(public productIds: number[]) {  // [1,2,3...)
  }
  public get product_ids() {
    return this.productIds.join(',');
  }
}

export const gqlProductFields = `{
  id
  title
  images {
    id
    uri
  }
  slug
  product_os_id
  points
  main_image {
    id
    uri
  }
  prices {
    currency_code
    price_partner
    price_customer
  }
  is_new
  is_hit
  avg_rating
  count_reviews
  in_cart
  in_favorites
  weight_netto
  weight_brutto
  price_customer
  price_partner
  currency
  status
}`;

export class GqlProductQuery extends GqlQuery {
  fields = gqlProductFields;
}
