import { User } from '../user/user.model';
import { Product } from '../product/product.model';
import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';

export enum Application_id {
  Angiolive = 1,
  VerteraGel = 2
}

export interface OrderStatus {
  type_id: number;
  name: string;
}

interface DeliveryType {
  type_id: number;
}

interface OrderItem {
  id: number;
  order_id: number;
  amount: number;
  price: number;
  product: Product;
}

export interface Storage {
  id: number;
  name: string;
  address: string;
}

interface Application {
  id: number;
  title: string;
}

export interface Order {
  id: number;
  id_os: number;
  storage_id: number;
  user_id: number;
  application_id?: number;
  status: OrderStatus;
  delivery_type: DeliveryType;
  delivery_price: number;
  currency_code: string;
  price_total: number;
  user: User;
  order_items: OrderItem[];
  storage: Storage;
  application: Application;
}

export class OrderQuery {
  storage_id: number;
  currency_code: string;
  slugs?: string;
  delivery_type_const: number;
  delivery_type_calculation_const: number;
  email: string;
  phone: string;
  fname: string;
  lname: string;
  ref_code?: string;
  agree: boolean;
  stat_uuid: string;
  delivery_point_id?: string;
  delivery_country_id: number;
  delivery_city_id: number;
}

export const gqlOrderItem = `{
  id
  order_id
}`;

export const gqlOrderFields = `{
  id
  id_os
  order_items ${gqlOrderItem}
}`;

export class GqlOrderQuery extends GqlQuery {
  fields = gqlOrderFields;
}
