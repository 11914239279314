import { Injectable, Inject, LOCALE_ID} from '@angular/core';
import { ILanguage } from '@core/components/languageSwitcherBase';


@Injectable({
  providedIn: 'root'
})
export class LanguageService  {

  constructor(@Inject(LOCALE_ID) private locale: string) { }

  getLanguage(): string {
    return this.locale.substr(0, 2);
  }

  getCurrentLanguage(languages: ILanguage[]): ILanguage {
    const language = this.getLanguage();
    const result = languages.find(lang => lang.code === language);
    return result ? result : languages[0];
  }
}
