export const SEARCH_COUNTRY = { ru: 'Поиск страны', en: 'Search country' };
export const REG_ERROR = {
  ru: 'Проверьте правильность введённых данных в полях с электронной почтой и контактным телефоном',
  en: 'Make sure you entered valid values in the e-mail and contact phone fields',
  es: 'Verifique la exactitud de los datos ingresados en los campos con correo electrónico y teléfono de contacto',
  cs: 'Zkontrolujte správnost zadaných údajů v polích pomocí e-mailu a kontaktního telefonu',
  bg: 'Проверете коректността на въведените данни в полетата с имейл и телефон за връзка',
  de: 'Überprüfen Sie die Richtigkeit der in den Feldern eingegebenen Daten mit E-Mail und Kontakttelefonnummer',
  hu: 'Make sure you entered valid values in the e-mail and contact phone fields',
};

export const PHONE_ERROR = {
  ru: 'Введите {n} цифр',
  en: 'Enter {n} digits',
  es: 'Ingrese {n} dígitos',
  cs: 'Zadejte {n} číslic',
  bg: 'Въведете {n} цифри',
  de: 'Geben Sie {n} Ziffern ein',
  tr: '{n} rakam girin',
  az: 'Vəsait {n} qəpik',
  fr: 'Entrez {n} chiffres',
  pt: 'Insira {n} dígitos',
  hu: 'Enter {n} digits',
};

