import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from '@core/lib/api.factory';
import { Observable } from 'rxjs';
import { PayModel, PayUrl } from '../../models/order/pay.model';
import { map } from 'rxjs/operators';

@Injectable()
export class PayService {

  constructor(
    @Inject(BASE_URL) private baseUrl: string,
    private http: HttpClient
  ) { }

  pay(query: PayModel): Observable<PayUrl[]> {
    return this.http.post<{data: PayUrl[]}>(`${this.baseUrl}/store/order/pay-urls?fields=url`, query).pipe(
      map( res => res.data )
    );
  }
}
