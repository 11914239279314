import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { AppStorage, CookieStorage } from '@core/storage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    AppModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: AppStorage,
      useClass: CookieStorage
    },
    {
      provide: 'LOCATION_HREF',
      useValue: location.href
    }
  ],
  bootstrap: [AppComponent]
})
export class AppBrowserModule { }
