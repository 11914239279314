import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { getLandingName } from '@core/lib/functions/getLandingName';
import { ActivatedRoute } from '@angular/router';
import { AppStorage } from '@core/storage';
import { LanguageService } from '../language.service';

@Injectable({
  providedIn: 'root'
})
export class RefService {

  readonly refKey: string = `${environment.appName}_${getLandingName()}_ref`;
  monthMs = 30 * 24 * 60 * 60 * 1000;

  constructor(
    private route: ActivatedRoute,
    @Inject(AppStorage) private appStorage: Storage,
    private languageService: LanguageService,
  ) { }

  setRef(param: string = 'ref') {
    const ref = this.route.snapshot.queryParamMap.get('ref') || this.route.snapshot.queryParamMap.get('bonus');
    if (ref) {
      this.appStorage.setItemWithPeriod(this.refKey, ref, this.monthMs); // 1 month
    }
  }

  getRef() {
    return this.route.snapshot.queryParamMap.get('ref') || this.appStorage.getItem(this.refKey);
  }

  clear() {
    this.appStorage.removeItem(this.refKey);
  }

  getVerteraLink(route, params = '' ): string {
    const urlParams = [this.getRefUrlParam(), 'global=true', params].join('&');
    const language = this.languageService.getLanguage();
    return `${language === 'ru' ?  environment.vertera.url : environment.vertera.eu.url}/${language}/${route}?${urlParams}`;
  }

  getRefUrlParam(): string {
    const ref = this.getRef();
    return ref ? `ref=${ref}` : '';
  }
}
