import { environment } from 'src/environments/environment';

export interface PayModel {
  order_id: number;
  url_success: string;
  url_failed: string;
  is_one_url: number;
}

export interface PayUrl {
  url: string;
}

export abstract class PayQuery {
  url_success: string;
  url_failed: string;
  abstract website: string;
  protected constructor(
    public order_id: number,
    public langCode: string,
  ) {
  }

  generateLinks(landingPayResultUrl: string) {
    const rootUrl = `${this.website}${this.langCode}${landingPayResultUrl}`;
    this.url_success = `${rootUrl}true`;
    this.url_failed = `${rootUrl}false`;
  }

  get query(): PayModel {
    return {
      order_id: this.order_id,
      url_success: this.url_success,
      url_failed: this.url_failed,
      is_one_url: 1
    };
  }
}


export class AngiolivePayQuery extends PayQuery {
  website = environment.angiolive.url;
  constructor(public order_id: number, langCode: string) {
    super(order_id, langCode);
    this.generateLinks(environment.angiolive.paySuccessUrl);
  }
}

export class ThalassolabPayQuery extends PayQuery {
  website = environment.thalassolab.url;
  constructor(public order_id: number, langCode: string) {
    super(order_id, langCode);
    this.generateLinks(environment.thalassolab.paySuccessUrl);
  }
}

export class MiraclePayQuery extends PayQuery {
  website = environment.miracle.url;
  constructor(public order_id: number, langCode: string) {
    super(order_id, langCode);
    this.generateLinks(environment.miracle.paySuccessUrl);
  }
}

export class SensationPayQuery extends PayQuery {
  website = environment.sensation.url;
  constructor(public order_id: number, langCode: string) {
    super(order_id, langCode);
    this.generateLinks(environment.sensation.paySuccessUrl);
  }
}

export class HcPayQuery extends PayQuery {
  website = environment.hc.url;
  constructor(public order_id: number, langCode: string) {
    super(order_id, langCode);
    this.generateLinks(environment.hc.paySuccessUrl);
  }
}
