import { HttpParams } from '@angular/common/http';

interface Name {
  kind: string;
  value: string;
}

interface Value {
  kind: string;
  value: string;
  block: boolean;
  values: Value[];
}

interface Argument {
  kind: string;
  name: Name;
  value: Value;
}

interface SelectionSet {
  kind: string;
  selections: Selection[];
}

interface Selection {
  kind: string;
  name: Name;
  arguments: Argument[];
  directives: any[];
  selectionSet: SelectionSet;
}

interface Definition {
  kind: string;
  operation: string;
  variableDefinitions: any[];
  directives: any[];
  selectionSet: SelectionSet;
}

interface Loc {
  start: number;
  end: number;
}

interface GQLtree {
  kind: string;
  definitions: Definition[];
  loc: Loc;
}

export function gqlToRest(model: GQLtree): string {
  const firstRoot = model.definitions[0].selectionSet.selections[0];
  let params = getQueries(firstRoot.arguments);
  const fields = getFieldsExpands(firstRoot.selectionSet);
  Object.keys(fields).forEach( key => {
    if (fields[key].length) {
      params = params.set(key, fields[key].join(','));
    }
  });
  return params.toString();
}

function getQueries(model: Argument[]): HttpParams {
  let params = new HttpParams();
  model.forEach(el => {
    if ((el.value.kind === 'IntValue' || el.value.kind === 'StringValue') && el.value.value) {
      params = params.append(
        el.name.value,
        el.value.value
      );
    }
    if (el.value.kind === `ListValue` && el.value.values.length) {
      // Только для IntValue
      params = params.append(
        el.name.value,
        el.value.values.map(child => child.value).join(',')
      );
    }
  });
  return params;
}

function getFieldsExpands(set: SelectionSet, expand = [], fields = [], expandName?: string): { expand: string[]; fields: string[] } {
  if (set && set.selections) {
    set.selections.forEach(selection => {
      if (!selection.selectionSet) {
        fields.push(getExpandName(expandName, selection));
      } else {
        expand.push(getExpandName(expandName, selection));
        return getFieldsExpands(selection.selectionSet, expand, fields, getExpandName(expandName, selection));
      }
    });
  }
  return {expand, fields};
}

function getExpandName(expandName: string, selection: Selection): string {
  return expandName ? `${expandName}.${selection.name.value}` : selection.name.value;
}
